import {
  Drawer, Typography, Box, List, ListItem, ListItemButton, ListItemText,
  ListItemIcon, Divider, Grid, useTheme
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { type BasicView } from "@models/basic-view.model";
import { type ViewDrawerProps } from "@models/view-drawer-props.model";
import { useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ViewDrawerLoader from "./view-drawer-loader/view-drawer-loader.component";

export interface BucketContainer {
  title: string;
  buckets: BasicView[];
}

const GREY = "#D3D3D3";
const WHITE = "#ffffff";
const SLIDEIN_ANIMATION_DELAY = 0.75;
const X_ANIMATION_DURATION = 1;

const ViewDrawer = ({ isLoading, selectedViewId, basicViews, open, toggleDrawer, onItemClick }: ViewDrawerProps): JSX.Element => {
  const muiTheme = useTheme();

  const myBuckets = basicViews.filter(basicView => basicView.type === "Created");
  const sharedBuckets = basicViews.filter(basicView => basicView.type === "Shared");
  const serviceLineBuckets = basicViews.filter(basicView => basicView.type === "ServiceLine");

  const getBucketGroups = useCallback((): Record<string, BasicView[]> => {
    const bucketSections: Record<string, BasicView[]> = {};
    if (serviceLineBuckets.length) {
      bucketSections.Presets = serviceLineBuckets;
    }

    if (sharedBuckets.length) {
      bucketSections["Shared with me"] = sharedBuckets;
    }

    if (myBuckets.length) {
      bucketSections["My buckets"] = myBuckets;
    }

    return bucketSections;
  }, [myBuckets, sharedBuckets, serviceLineBuckets]);

  const bucketGroups = getBucketGroups();

  const renderItemContent = (basicView: BasicView, index: number): JSX.Element => {
    const createdDate = dayjs(basicView.created.createdOn).format("YYYY-MM-DD");
    let description = createdDate;

    if (basicView.type === "Shared" && basicView.created.userName) {
      description = `${createdDate} from ${basicView.created.userName}`;
    }

    const notAcceptedIcon = basicView.fromSync
      ? (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 3, duration: 0.5 }}
        >
          <ListItemIcon sx={{ justifyContent: "end" }}>
            <motion.div
              animate={{ opacity: 1, scale: [1, 1.3, 1] }}
              transition={{ delay: 3.5, duration: 1.5, ease: "easeInOut", repeat: Infinity }}
            >
              <CircleIcon sx={{ color: "black", fontSize: "1.2rem" }} />
            </motion.div>
          </ListItemIcon>
        </motion.div>
      ) : (
        <ListItemIcon sx={{ justifyContent: "end" }}>
          <CircleIcon sx={{ color: "black", fontSize: "1.2rem" }} />
        </ListItemIcon>
      );

    const listItem = (
      <ListItem
        key={`${basicView.name}-${index}`}
        disablePadding
        onClick={() => onItemClick(basicView)}
      >
        <ListItemButton
          selected={basicView?.id === selectedViewId}
        >
          <ListItemText
            primaryTypographyProps={{ color: "black", fontWeight: basicView?.accepted === Boolean(false) ? "bold" : "normal" }}
            primary={basicView.name}
            secondaryTypographyProps={{ color: "grey" }}
            secondary={description}
          />
          {basicView?.id === selectedViewId
                && (
                  <ListItemIcon sx={{ justifyContent: "end" }}>
                    <CheckIcon fontSize="medium" color="action" />
                  </ListItemIcon>
                )}
          {basicView?.accepted === Boolean(false) && notAcceptedIcon}
        </ListItemButton>
      </ListItem>
    );

    return basicView.fromSync
      ? (
        <motion.div
          initial={{ opacity: 1, height: 0, x: "-200%", scaleX: 0, backgroundColor: GREY }}
          animate={{ opacity: 1, height: "auto", x: 0, scaleX: 1, backgroundColor: [GREY, WHITE] }}
          transition={{
            // The animation parts play in sequence, not in parallel.
            // The row slides in after the space for it has fully expanded.
            duration: SLIDEIN_ANIMATION_DELAY,
            x: { delay: SLIDEIN_ANIMATION_DELAY, duration: X_ANIMATION_DURATION },
            scaleX: { delay: SLIDEIN_ANIMATION_DELAY, duration: X_ANIMATION_DURATION },
            backgroundColor: { delay: 3, duration: 1 }
          }}
        >
          {listItem}
        </motion.div>
      ) : listItem;
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
    >
      <Box sx={{ width: "22rem", overflowY: "auto", height: "calc(100vh - 3rem)" }} role="presentation">
        <Grid item container sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h4" sx={{ color: "black", p: 2 }}>
            Select bucket
          </Typography>
          <CloseIcon onClick={toggleDrawer(false)} fontSize="medium" color="action" sx={{ m: "0.5rem" }} />
        </Grid>
        {isLoading
          ? <ViewDrawerLoader />
          : (
            <div>
              {
                basicViews?.length === 0 ? (
                  <div style={{ marginLeft: "1rem" }}>
                    <p>You currently have no bucket to select from.</p>
                    <p>Please create a new bucket.</p>
                  </div>
                ) : (
                  <AnimatePresence>
                    {Object.keys(bucketGroups).map((title: string, index: number) => (
                      <List
                        key={`${title}-${index}`}
                        sx={{
                          maxHeight: "100% !important",
                          "&& .Mui-selected, && .Mui-selected:hover": {
                            bgcolor: muiTheme.palette.action.selected
                          }
                        }}
                      >
                        <Divider />
                        <Typography variant="h5" sx={{ color: "black", p: 2 }}>
                          {title}
                        </Typography>
                        {
                              bucketGroups[title]?.map((basicView: BasicView, idx: number) => renderItemContent(basicView, idx))
                            }
                      </List>
                    ))}
                  </AnimatePresence>
                )
              }
            </div>
          )}
      </Box>
      <Grid
        container
        item
        sx={{ justifyContent: "center", position: "absolute", cursor: "pointer", bottom: "1rem" }}
        onClick={toggleDrawer(false)}
      >
        <Typography variant="h5" color="black">
          CLOSE
        </Typography>
      </Grid>
    </Drawer>
  );
};

export default ViewDrawer;
